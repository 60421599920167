<template>
  <div>
    <h2 style="text-align: center">{{ urlParams }}</h2>
  </div>
</template>

<script>
export default {
  name: 'Sso',
  beforeMount: function () {
    this.authenticate();
  },

  methods: {
    async authenticate() {
      try {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('bearer') && urlParams.get('refresh')) {
          localStorage.setItem('access_Token', urlParams.get('bearer'));
          localStorage.setItem('refresh_Token', urlParams.get('refresh'));
          this.$router.push('/home');
        } else {
          this.$route.push('/forbidden');
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
